import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Web3 from "web3";

//ABI
import config from "../config/config.js";
import ExcelUploadContractABI from "../ABI/excelUploadContract.json";
import BUSDcontractABI from "../ABI/busdContract.json";

let toasterOption = config.toasterOption;

export default function Transferdetails({ userCount, totalAmount }) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const { web3, address, network } = walletConnection;
  const [tokenBalance, setTokenBalance] = useState(0);
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [ownerAdd, setOwnerAdd] = useState(null);

  useEffect(() => {
    getconnect();
  }, [walletConnection?.address]);

  useEffect(() => {
    if (tokenBalance != null) {
      let bal = totalAmount - tokenBalance;
      if (bal >= 0) {
        setRemainingBalance(bal);
      }
    }
  }, [tokenBalance, totalAmount]);

  async function getconnect() {
    var web3 = new Web3(walletConnection?.web3);
    if (web3) {
      try {
        if (web3 !== undefined) {
          try {
            let BUSDcontract = new web3.eth.Contract(
              BUSDcontractABI,
              config.BUSDcontract
            );
            //CONTRACT
            let Exlcontract = new web3.eth.Contract(
              ExcelUploadContractABI,
              config.ExcelUploadContract
            );
            let Tokenbalnce = await BUSDcontract.methods
              .balanceOf(config.ExcelUploadContract)
              .call();
            setTokenBalance((Tokenbalnce / 1e18));

            let ownerAddress_val = await Exlcontract.methods
              .admin()
              .call();
            setOwnerAdd(ownerAddress_val);
          } catch (err) { console.log(err); return false; }
        }
      } catch (err) { console.log(err); }
    }
  };

  const transferToWallet = async () => {
    if (remainingBalance > 0) {
      if (
        walletConnection &&
        web3 &&
        address &&
        address != undefined &&
        address != "" &&
        network != ""
      ) {
        if (web3 && address) {
          try {
            if (web3 !== undefined) {
              let BUSDcontract = new web3.eth.Contract(
                BUSDcontractABI,
                config.BUSDcontract
              );
              let remainBal = Math.ceil(remainingBalance);       
              let amt = convert((remainBal * 1e18))?.toString();
              console.log("Tranfer wallet amount:=",amt);           
              var bnbBal = await web3.eth.getBalance(address);
              try {
                let gasPrice = await web3.eth.getGasPrice();
                let estimateGas = await BUSDcontract.methods.transfer(config.ExcelUploadContract, amt).estimateGas({ from: walletConnection.address });
                estimateGas = estimateGas + 50000;
                
                var spendGas = gasPrice * estimateGas;              
                if (spendGas > bnbBal) {
                  toast.error("Insufficient Funds for gas fee", toasterOption);
                  return;
                }
                let res = await BUSDcontract.methods.transfer(config.ExcelUploadContract, amt).send({ from: walletConnection.address, gas: estimateGas, gasPrice: gasPrice });
                if (res?.status) {
                  toast.success("Amount transfer successfully.", toasterOption);
                  setTokenBalance(tokenBalance + remainBal);
                  setRemainingBalance(0);
                } else {
                  toast.error("Amount transfer failed.", toasterOption);
                }
              } catch (e) {
                var pos = e.search("funds")
                if (pos >= 0) {
                  toast.error("Insufficient Funds for gas fee", toasterOption);
                  return false;
                } else {
                  toast.error("Something went wrong.", toasterOption);
                  console.log(e);
                  return false;
                }
              }
            }
          } catch (err) { console.log(err); return false; }
        }
      }
    } else {
      if (ownerAdd != address) {
        toast.error("Please connect the wallet to the owner address.", toasterOption);
        return false;
      }
      toast.warning("Contract balance is sufficient.", toasterOption);
    }
  }

  function convert(n) {
    try {
      var sign = +n < 0 ? "-" : "",
        toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead, decimal, pow] = n.toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);
      return +pow < 0
        ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
        : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    } catch (err) {
      return 0;
    }

  }
  return (
    <>
      <div className="white_box">
        <Row>
          <Col lg={6} className="mb-3">
            <div className="dflex box1">
              <div className="dflex">
                <img
                  src={require("../assets/image/icon1.png")}
                  alt="img"
                  className="img-fluid me-3"
                />
                <h5 className="h5tag">Total Users</h5>
              </div>
              <div>
                <h2 className="h2tag">{userCount}</h2>
              </div>
            </div>
          </Col>
          <Col lg={6} className="mb-3">
            <div className="dflex box1">
              <div className="dflex">
                <img
                  src={require("../assets/image/icon2.png")}
                  alt="img"
                  className="img-fluid me-3"
                />
                <h5 className="h5tag">Distribute Amount</h5>
              </div>
              <div>
                <h2 className="h2tag">${totalAmount}</h2>
              </div>
            </div>
          </Col>
          <Col lg={6} className="mb-3">
            <div className="dflex box1">
              <div className="dflex">
                <img
                  src={require("../assets/image/icon3.png")}
                  alt="img"
                  className="img-fluid me-3"
                />
                <h5 className="h5tag">Contract Balance</h5>
              </div>
              <div>
                <h2 className="h2tag">${tokenBalance}</h2>
              </div>
            </div>
          </Col>
          <Col lg={6} className="mb-3">
            <div className="dflex box1">
              <div className="dflex">
                <img
                  src={require("../assets/image/icon5.png")}
                  alt="img"
                  className="img-fluid me-3"
                />
                <h5 className="h5tag">User Upload Per Transaction</h5>
              </div>
              <div className="dflex">
                <h2 className="h2tag">{config?.partialUpload}</h2>
              </div>
            </div>
          </Col>
          <Col lg={12}>
            <div className="dflex box1">
              <div className="dflex">
                <img
                  src={require("../assets/image/icon4.png")}
                  alt="img"
                  className="img-fluid me-3"
                />
                <h5 className="h5tag">Remaining Balance</h5>
              </div>
              <div className="dflex">
                <h2 className="h2tag me-4">{remainingBalance}</h2>
                <button type="button" className="primary_btn" onClick={() => transferToWallet()}>Transfer to contract</button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
