import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/image/Logo.svg";
import file from "../assets/image/file.svg";

import config from "../config/config"
import Web3 from "web3";
import { toast } from "react-toastify";
import { Dropdown, Button, DropdownButton, Modal, Row, Col } from "react-bootstrap";

//
import BUSDcontractABI from "../ABI/busdContract.json";
import { walletClientToSigner } from "../ethersConnect";
import {
  useWalletClient,
  useDisconnect,
  useConnect,
  useNetwork,
  useAccount,
} from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import { setWallet } from "../reducers/Actions";
let toasterOption = config.toasterOption;

// import $ from 'jquery';
export default function Navbar(props) {
  //   useEffect(() => {
  //     loadScript();
  //   }, []);

  //   function loadScript() {
  //     // Navbar Sticky
  //     var t = $(".navbar-sticky");
  //     $(window).on("scroll load", function (e) {
  //       var e = $(window).scrollTop();
  //       120 <= e
  //         ? t.addClass("navbar-sticky--moved-up")
  //         : t.removeClass("navbar-sticky--moved-up");
  //       250 <= e
  //         ? t.addClass("navbar-sticky--transitioned")
  //         : t.removeClass("navbar-sticky--transitioned");
  //       500 <= e
  //         ? t.addClass("navbar-sticky--on")
  //         : t.removeClass("navbar-sticky--on");
  //     });
  //   }


  //Wallet connection//
  const [show, setShow] = useState(false);
  const [BNBBalnce, setBNBBalnce] = useState(0);
  const [BUSDblns, setBUSDblns] = useState(0);
  const walletConnection = useSelector((state) => state.walletConnection);

  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();
  const { disconnect } = useDisconnect();
  const { chain, chains } = useNetwork();
  const dispatch = useDispatch();

  useAccount({
    onConnect: ({ address, connector, isReconnected }) => {
      console.log("Connected", { address, connector, isReconnected });
    },
    onDisconnect: () => {
      console.log("Disconnected");
      var web3 = new Web3(config.rpcUrl);
      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: web3,
          address: "",
          provider: null,
          connect: "",
          isChange: "",
        })
      );
      setTimeout(function () {
        window.location.reload(false);
      }, 1500);
    },
  });

  let chainId = config.NetworkId;
  const { data: walletClient } = useWalletClient({ chainId });

  useEffect(() => {
    //if (walletClient) {
    setConnection();
    //}
  }, [walletClient, chain?.network]);

  async function setConnection() {
    if (walletClient && chain && chain.id == config.NetworkId) {
      var { signer, transport } = walletClientToSigner(walletClient);
      var web3 = new Web3(transport);
      if (chain.id == config.NetworkId) {
        dispatch(
          setWallet({
            network: config.NetworkId,
            web3: web3,
            address: walletClient.account.address,
            provider: transport,
            connect: "yes",
            isChange: "false",
          })
        );
      }
    } else {
      var web3 = new Web3("https://bsc-dataseed1.binance.org");
      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: web3,
          address: "",
          provider: null,
          connect: "",
          isChange: "",
        })
      );
    }
  }


  function isMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  const connectMetamaskMobile = () => {
    const dappUrl = window.location.href.split("//")[1].split("/")[0];
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    window.open(metamaskAppDeepLink, "_self");
  };

  async function connectfunction(connector) {
    setShow(false);
    try {
      let check = isMobile();

      if (
        check &&
        !window.ethereum &&
        connector &&
        connector.connector.id != "walletConnect"
      ) {
        await connectMetamaskMobile();
        return;
      }
      if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        var currnetwork = await web3.eth.net.getId();
        console.log(currnetwork, "currnetworkc", config.NetworkId);
        if (
          parseInt(currnetwork) != config.NetworkId &&
          connector.connector.id == "metaMask"
        ) {
          toast.error("Please Connect BNB Network", toasterOption);
          return;
        }
      }

      var resp = connect(connector);
      window.$("#connect_wallet_modal").modal("hide");
      setTimeout(function () {
        //window.location.reload(false);
      }, 1000);
    } catch (err) { }
  }


  async function disconnectMetamask() {
    var token = localStorage.getItem("admin_token");
    localStorage.clear();
    localStorage.setItem("admin_token", token);

    disconnect();

    setTimeout(function () {
      window.location.reload(false);
    }, 1000);
  }

  useEffect(() => {
    getconnect();
  }, [walletConnection, walletClient]);

  async function getconnect() {
    if (
      walletConnection &&
      walletConnection.web3 &&
      walletConnection.address &&
      walletConnection.address != undefined &&
      walletConnection.address != "" &&
      walletConnection.network != ""
    ) {
      let address = walletConnection.address;
      var web3 = walletConnection.web3;

      if (web3 && address) {
        try {
          if (web3 !== undefined) {
            // getBNB balance
            var balance = await web3.eth.getBalance(address);
            var currbal = balance / config.decimals;
            setBNBBalnce(currbal.toFixed(4));

            //getTokenbalnce
            var BUSDcontract = new web3.eth.Contract(
              BUSDcontractABI,
              config.BUSDcontract
            );

            let Tokenbalnce = await BUSDcontract.methods
              .balanceOf(walletConnection.address)
              .call();
            var bal = Tokenbalnce / config.decimals;
            setBUSDblns(bal.toFixed(4));
          }
        } catch (err) { }
      }
    }
  }


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 15) + "...." + addr.slice(addr.length - 7);
    } else {
      return "";
    }
  };
  //Wallet connection//

  return (
    <>
      {
        <nav class="navbar navbar-expand-sm nav_bar">
          <div class="container">
            <a class="navbar-brand" href="#">
              {" "}
              <img src={logo} className="img-fluid brand_logo" alt="logo" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="offcanvas offcanvas-end "
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                  <img src={logo} className="img-fluid brand_logo" alt="logo" />
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">

               {walletConnection && walletConnection.address == "" ? (
                  <button class="btn primary_btn" type="button" onClick={handleShow}>
                    <img src={file} alt="img" className="img-fluid pe-3" />{" "}
                    Connect Wallet
                  </button>
                ) : (
                  <DropdownButton
                    id="dropdown-button-dark-example2"
                    variant="primary"
                    title={halfAddrShow(walletConnection?.address)}
                    data-bs-theme="dark"
                  >
                    <Dropdown.Item>Balance in BNB : {BNBBalnce}</Dropdown.Item>
                    <Dropdown.Item>Balance in BUSD : {BUSDblns}</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => disconnectMetamask()}>
                      Disconnect
                    </Dropdown.Item>
                  </DropdownButton>
                )}

              </div>
            </div>
          </div>
        </nav>
      }

      {/* Connect Wallet Modal */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Connect Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="walletButtonConnect">
            {connectors &&
              connectors.length > 0 &&
              connectors.map((connector) => (
               <>
                  {connector && connector.id == "metaMask" ? (
                   
                      <Button
                        variant="outline-danger"
                        onClick={() => connectfunction({ connector })}
                      >
                        <img
                          src={require("../assets/image/metamask.png")}
                          height="28"
                          width="28"
                          className="img-fluid"
                        />{" "}
                        Metamask
                      </Button>
                  
                  ) : (
                    ""
                  )}
                  {connector && connector.id == "walletConnect" ? (
                  
                      <Button
                        variant="outline-info"
                        onClick={() => connectfunction({ connector })}
                      >
                        <img
                          src={require("../assets/image/trustwallet.png")}
                          height="28"
                          width="28"
                          className="img-fluid"
                        />{" "}
                        Trust Wallet
                      </Button>
                  
                  ) : (
                    ""
                  )}
                </>
              ))}
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
}
