import React, { useState, useEffect } from "react";
import Navbar from "../components/navbar";
import { Container } from "react-bootstrap";
import DocumentUpload from "../components/documentupload";


export default function ExcelUpload() {
  return (
    <>
      <div className="excel_upload_page">
        <Navbar />
        <Container>
          <div className="mt-5">
            <h2 className="h2tag p-2">Document Upload</h2>
            <DocumentUpload />
          </div>
        </Container>
      </div>
    </>
  );
}
