import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import fileImg from "../assets/image/file.svg";
import * as XLSX from 'xlsx';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Web3 from "web3";

//Components
import Transferdetails from "../components/transferdetails";
import UploadHistory from "../components/uploadhistory";
import DocumentUploadProgress from "../components/documentuploadprogress";

import ExcelUploadContractABI from "../ABI/excelUploadContract.json";
import config from "../config/config.js";
let toasterOption = config.toasterOption;

export default function DocumentUpload() {
  const walletConnection = useSelector((state) => state.walletConnection);
  const { web3, address, network } = walletConnection;

  const [data, setData] = React.useState(null);
  const [file, setFile] = useState(null);
  const [usrAddress, setUsrAddress] = useState([]);
  const [amount, setAmount] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [ownerAdd, setOwnerAdd] = useState(null);

  useEffect(() => {
    getconnect();
  }, []);

  useEffect(() => {
    if (data != null) {
      let filterKey = { address: "Address", amount: "Amount" };
      let res = {};
      let count = 0;
      let totAmount = 0;
      let finalResult = data.reduce((acc, obj) => {
        if (obj?.hasOwnProperty(filterKey?.address) && obj?.hasOwnProperty(filterKey?.amount)) {
          count += 1;
          if (res?.hasOwnProperty(obj?.[filterKey?.address])) {
            res[obj?.[filterKey?.address]] = convert(parseFloat(res[obj?.[filterKey?.address]]) + (parseFloat(obj?.[filterKey?.amount]) * 1e18))?.toString();
            totAmount += parseFloat(obj?.[filterKey?.amount]);
          }
          else {
            res[obj?.[filterKey?.address]] = convert(parseFloat(obj?.[filterKey?.amount]) * 1e18)?.toString();
            totAmount += parseFloat(obj?.[filterKey?.amount]);
          }
        }
        return res;
      }, []);
      // console.log("array:=", finalResult)
      // console.log("keys:=", Object.keys(finalResult))
      // console.log("keys:=", JSON.stringify(Object.keys(finalResult), null, 2).replace(/"([^"]+)":/g, '$1:'))
      // console.log("values:=", Object.values(finalResult))

      setTotalAmount(totAmount);
      setUsrAddress(Object.keys(finalResult));
      setAmount(Object.values(finalResult));
    }
  }, [data]);

  useEffect(() => {
    if (usrAddress?.length == 0 && file != null) {      
      toast.error("Address & Amount fields not found.", toasterOption);
      setFile(null);
    }else if(usrAddress?.length > 0 && file != null){
      checkTokenBalance(usrAddress?.length);
      if(validateWalletAddresses(usrAddress)?.length > 0){
        toast.error("Some of the address is wrong.", toasterOption);
        setFile(null);       
      }     
    }
  }, [usrAddress]);

  const validateWalletAddresses = (addresses) => {
    const validAddress = addresses.filter(address => (!web3.utils.isAddress(address)));
    return validAddress;
  };

  const checkTokenBalance = async(len) =>{
    let bnbBal = await web3.eth.getBalance(address);
    let availBal = Math.ceil(len / config?.partialUpload) * 0.015;
    if(bnbBal < availBal)
    {
      toast.error(`Your token balance is low. Required balance is ( ${availBal} )`, toasterOption);
      setFile(null);    
    }    
  }

  async function getconnect() {
    const web3 = new Web3(config.infuraRpc);
    if (web3) {
      try {
        if (web3 !== undefined) {
          //CONTRACT
          let Exlcontract = new web3.eth.Contract(
            ExcelUploadContractABI,
            config.ExcelUploadContract
          );

          let ownerAddress_val = await Exlcontract.methods
            .admin()
            .call();
          setOwnerAdd(ownerAddress_val);
        }
      } catch (err) { }
    }
  };

  function convert(n) {
    try {
      var sign = +n < 0 ? "-" : "",
        toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead, decimal, pow] = n.toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);
      return +pow < 0
        ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
        : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    } catch (err) {
      return 0;
    }
  }

  const handleFileUpload = (e) => {
    if (
      walletConnection &&
      web3 &&
      address &&
      address != undefined &&
      address != ""
    ) {
      if (web3 && address) {
        try {
          if (web3 !== undefined) {
            //Valid - Owner address
            if (ownerAdd != address) {
              toast.error("Please connect owner address", toasterOption);
              return false;
            }
          }
        }
        catch (err) { console.log(err); }
      }
    } else {
      toast.error("Wallet not connected", toasterOption);
      return false;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    const fileExtension = file.name.split('.').pop();
    if (fileExtension == "xls" || fileExtension == "xlsx") {
      setFile(file);
      reader.onload = (event) => {
        const workbook = XLSX.read(event.target.result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(sheet);
        setData(sheetData);
      };
      reader.readAsBinaryString(file);
    }
    else {
      toast.error("Please upload the file format correctly. Supported file format (xls,xlsx).", toasterOption);
    }
    e.target.value = "";
  };


  return (
    <>
      {file == null ? (
        <div className="white_box">
          <Row>
            <Col lg={4}>
              <div className="d-none d-lg-block text-center mt-4">
                <img
                  src={require("../assets/image/ban1.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </Col>
            <Col lg={8}>
              <div class="upload-container">
                <div class="upload-area" id="uploadArea">
                  <img
                    src={require("../assets/image/icon6.png")}
                    alt="img"
                    className="img-fluid mb-3"
                  />
                  {(ownerAdd == address) ? (
                    <>
                      <Button className="primary_btn">
                        {" "}
                        <img src={fileImg} alt="img" className="img-fluid pe-3" />
                        Choose a file
                      </Button>
                      <input type="file" id="fileInput" onChange={handleFileUpload} />
                    </>
                  ) : (
                    <>
                      <Button className="primary_btn" onClick={()=>toast.error("Please connect the wallet to the owner address", toasterOption)}>
                        {" "}
                        <img src={fileImg} alt="img" className="img-fluid pe-3" />
                        Choose a file
                      </Button>                      
                    </>
                  )}
                </div>
                <div class="file-list" id="fileList"></div>
              </div>
              <div className="dflex">
                <p className="ptag">Supported formats: XLS, XLSX</p>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <DocumentUploadProgress usraddress={usrAddress} amount={amount} fileName={new Date().getTime()?.toString() + file?.name?.split(".")?.[1]} />
      )}

      <h2 className="h2tag p-2">Transfer Details</h2>
      <Transferdetails userCount={usrAddress?.length} totalAmount={totalAmount} />
      
      <UploadHistory />

    </>
  );
}
