import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import config from "../config/config.js";
import { useSelector } from "react-redux";
import Web3 from "web3";
import { toast } from "react-toastify";

import ExcelUploadContractABI from "../ABI/excelUploadContract.json";
import BUSDcontractABI from "../ABI/busdContract.json";
let toasterOption = config.toasterOption;

export default function DocumentUploadProgress(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const { web3, address, network } = walletConnection;
  //PROPS
  let { usraddress, amount, fileName } = props;

  //STATE
  const [spliceEligible, setSpliceEligible] = useState(null);
  const [uploadIDX, setUploadIDX] = useState(1);
  const [ownerAdd, setOwnerAdd] = useState(null);
  const [loader, setLoader] = useState(false);
  const [transactionHash, setTransactionHash] = useState([]);

  // console.log("address:=", address);
  // console.log("amount:=", amount);
  useEffect(() => {
    if (usraddress?.length > 0) {
      setSpliceEligible(Math.ceil(usraddress?.length / config?.partialUpload));
    }
  }, [usraddress])

  useEffect(() => {
    getconnect();
  }, []);

  useEffect(() => {
    if (uploadIDX === (spliceEligible + 1)) {
      localStorage.setItem('transaction_hash', JSON.stringify(transactionHash));
    }
  }, [uploadIDX])

  async function getconnect() {
    const web3 = new Web3(config.infuraRpc);
    if (web3) {
      try {
        if (web3 !== undefined) {
          //CONTRACT
          let Exlcontract = new web3.eth.Contract(
            ExcelUploadContractABI,
            config.ExcelUploadContract
          );

          let ownerAddress_val = await Exlcontract.methods
            .admin()
            .call();
          setOwnerAdd(ownerAddress_val);
        }
      } catch (err) { }
    }
  };

  const partUpload = async (idx) => {

    //Token balance checking once for upload all partitions
    if (idx == 1) {
      let amt = amount.reduce(function (val1, val2) {
        return parseFloat(val1) + parseFloat(val2);
      }, 0);
      //Token Contract
      let BUSDContract = new web3.eth.Contract(
        BUSDcontractABI,
        config.BUSDcontract
      );
      //Token balance - Checking
      let tokenBalance = await BUSDContract.methods
        .balanceOf(config.ExcelUploadContract)
        .call();
      if (parseInt(amt / 1e18) > Number(tokenBalance / 1e18)) {
        toast.error(`Your token balance is low`, toasterOption);
        return false;
      }
      localStorage.setItem('transaction_hash', "");
    }

    if (
      walletConnection &&
      web3 &&
      address &&
      address != undefined &&
      address != "" &&
      network != ""
    ) {
      if (web3 && address) {
        try {
          if (web3 !== undefined) {
            let transHashObj = { user: "", hash: "" };
            //Excel upload contract
            let Exlcontract = new web3.eth.Contract(
              ExcelUploadContractABI,
              config.ExcelUploadContract
            );
            //Token Contract
            let BUSDContract = new web3.eth.Contract(
              BUSDcontractABI,
              config.BUSDcontract
            );
            //Valid - Owner address                   
            if (ownerAdd != address) {
              toast.error("Please connect owner address", toasterOption);
              return false;
            }

            let emtyAddressArr = [...usraddress];
            let emtyAmountArr = [...amount];
            let spliceAddressData = new Array();
            let spliceAmountData = new Array();

            if (idx == 1) {
              spliceAddressData = emtyAddressArr.splice(0, config?.partialUpload);
              spliceAmountData = emtyAmountArr.splice(0, config?.partialUpload);
              if (usraddress?.length >= config?.partialUpload) {
                transHashObj.user = "1 - " + config?.partialUpload;
              }
              else {
                transHashObj.user = "1 - " + usraddress?.length;
              }
            }
            else {
              let startIndex = ((idx - 1) * (config?.partialUpload));
              spliceAddressData = emtyAddressArr.splice(startIndex, (config?.partialUpload));
              spliceAmountData = emtyAmountArr.splice(startIndex, (config?.partialUpload));

              if (idx == spliceEligible) {
                transHashObj.user = (startIndex + 1)?.toString() + " - " + ((startIndex) + (usraddress?.length - startIndex))?.toString();
              }
              else {
                transHashObj.user = (startIndex + 1)?.toString() + " - " + (idx * config?.partialUpload)?.toString();
              }
            }

            let amt = spliceAmountData.reduce(function (val1, val2) {
              return parseFloat(val1) + parseFloat(val2);
            }, 0);

            //Token balance - Checking
            let tokenBalance = await BUSDContract.methods
              .balanceOf(config.ExcelUploadContract)
              .call();

            if (parseInt(amt / 1e18) > Number(tokenBalance / 1e18)) {
              toast.error(`Your token balance is low`, toasterOption);
              return false;
            }
            setLoader(true);

            var bnbBal = await web3.eth.getBalance(address);
            console.log("track:=", spliceAddressData, spliceAmountData, fileName, usraddress?.length)
            try {
              let gasPrice = await web3.eth.getGasPrice();
              let estimateGas = await Exlcontract.methods.batchTransfer(spliceAddressData, spliceAmountData, fileName, usraddress?.length).estimateGas({ from: walletConnection.address });
              estimateGas = estimateGas + 30000;

              var spendGas = gasPrice*estimateGas;
              console.log("spendgas",spendGas)
              if(spendGas>bnbBal){
                toast.error("Insufficient Funds for gas fee", toasterOption); setLoader(false);
                return;
              }

              let res = await Exlcontract.methods.batchTransfer(spliceAddressData, spliceAmountData, fileName, usraddress?.length).send({ from: walletConnection.address, gas: estimateGas, gasPrice: gasPrice });

              if (res?.status) {
                transHashObj.hash = res?.transactionHash;
                setUploadIDX(idx + 1);
                setLoader(false);
                setTransactionHash(oldData => [...oldData, transHashObj]);
                toast.success("Amount transfer successfully.", toasterOption);
                if (idx == Math.ceil(usraddress?.length / config?.partialUpload)) {
                  window.location.reload();
                }
              } else {
                toast.error("Amount transfer failed.", toasterOption); setLoader(false);
              }
            }
            catch (e) {
              var pos = e.search("funds")
              if(pos>=0){
                toast.error("Insufficient Funds for gas fee", toasterOption); setLoader(false);
                return;
              }else{
                toast.error("Something went wrong.", toasterOption); setLoader(false);
                console.log(e);
              }                 
            }
          }
        }
        catch (e) { 
          console.log(e); setLoader(false); 
        }
      }
    }
    else {
      toast.error(`Wallet not connected`, toasterOption);
    }
  };

  return (
    <>
      <div className="white_box">
        <Row>
          <Col lg={4}>
            <div className="d-none d-lg-block text-center mt-4">
              <img
                src={require("../assets/image/ban1.png")}
                alt="img"
                className="img-fluid"
              />
            </div>
          </Col>
          <Col lg={8}>
            <div className="dotted_box">
              {spliceEligible > 0 && (
                <>
                  {
                    Array.from({ length: spliceEligible }, (value, idx) => {
                      let ridx = idx + 1;
                      let fromUsr = 0, toUsr = 0;
                      if (ridx == spliceEligible) {
                        fromUsr = idx * config?.partialUpload;
                        toUsr = (fromUsr) + (usraddress?.length - fromUsr);
                      }
                      else {
                        fromUsr = idx * config?.partialUpload;
                        toUsr = ridx * config?.partialUpload;
                      }
                      return (
                        <div className="white_box ">
                          <div className="dflex mb-3">
                            <div className="dflex user_count">
                              <img
                                src={require("../assets/image/icon10.png")}
                                alt="img"
                                className="img-fluid me-2"
                              />
                              <div>
                                <h6 className="h6tag mb-0">SET - {ridx}</h6>
                                <p className="ptag mb-0">{fromUsr + 1} - {toUsr} Users</p>
                              </div>
                            </div>

                            {uploadIDX > ridx ? (
                              <div className="dflex">
                                <img
                                  src={require("../assets/image/finished.png")}
                                  alt="img"
                                  className="img-fluid me-2"
                                />
                                <h5 className="h5tag voilet_text mb-0">Complete</h5>
                              </div>
                            ) : (
                              <div className="dflex">
                                <button class="btn primary_btn " type="button" disabled={(uploadIDX == ridx && loader == false) ? false : true} onClick={() => partUpload(ridx)}>
                                  {loader && (uploadIDX == ridx) && (
                                    <div class="spinner-border spinner-border-sm" role="status">
                                    </div>)} Send
                                </button>
                              </div>
                            )}

                          </div>
                          <div className="">
                            {uploadIDX > ridx ? (
                              <>
                                <ProgressBar now={100} className="custom-progress" />
                                <p className="ptag text-end mb-0">100%</p>
                              </>
                            ) : (
                              <>
                                <ProgressBar now={0} className="custom-progress" />
                                <p className="ptag text-end mb-0">0%</p>
                              </>
                            )}
                          </div>
                        </div>
                      )
                    })}
                </>
              )}

              <button class="btn primary_btn " type="button" onClick={() => window.location.reload()}>
                Cancel
              </button>

            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
