import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//Components
import ExcelUpload from "./pages/excelupload";

//redux
import { Provider } from "react-redux";
import store from "./store";
//Wagmi provider
import { WagmiConfig, createConfig, configureChains } from "wagmi";
import { bsc, bscTestnet } from "viem/chains";
import { publicProvider } from "wagmi/providers/public";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc, bscTestnet],
  [publicProvider()]
);
console.log({ chains }, "chainschainschainswwwww");

// Set up wagmi config
const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains,
      shimDisconnect: true,
      shimChainChangedDisconnect: true,
    }),
    new WalletConnectConnector({
      chains,
      shimDisconnect: true,
      shimChainChangedDisconnect: true,
      options: {
        projectId: "681700e231a5aef269b7fe4adb34981a",
        version: "2",
      },
    }),
    new InjectedConnector({
      chains,
      shimDisconnect: true,
      shimChainChangedDisconnect: true,
      options: {
        name: "Injected",
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <WagmiConfig config={config}>
        <BrowserRouter basename="/">
          <Routes>
            <Route exact
              path="/"
              element={<ExcelUpload />}
            />
          </Routes>
          <ToastContainer />
        </BrowserRouter>
      </WagmiConfig>
    </Provider>
  );
};

export default AppWrapper;
