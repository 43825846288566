import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Web3 from "web3";
import config from "../config/config.js";
import ExcelUploadContractABI from "../ABI/excelUploadContract.json";
let toasterOption = config.toasterOption;

const columns = [
  {
    name: "Date",
    selector: (row) => row.Date,
    sortable: true,
  },
  // {
  //   name: "Excel Name",
  //   selector: (row) => row.Excel,
  //   sortable: true,
  // },
  {
    name: "Total no of users",
    selector: (row) => row.users,
    sortable: true,
  },
  {
    name: "Total no of Upload",
    selector: (row) => row.Upload,
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.Status,
    sortable: true,
  },
];

export default function UploadHistory() {
  const walletConnection = useSelector((state) => state.walletConnection);
  const { web3, address, network } = walletConnection;
  const [historyData, setHistoryData] = useState(null);
  const [formatData, setFormatData] = useState([]);
  const [transHash, setTransHash] = useState([]);

  useEffect(() => {
    getconnect();
  }, [walletConnection?.address]);

  useEffect(() => {
    if (historyData != null) {
      let frmtData = new Array();
      historyData[0].forEach((value, index) => {
        let timestamp = historyData?.[3]?.[index] * 1000;
        let date = new Date(timestamp);
        let formattedDate = date.toLocaleString();

        let data = {
          timestamp: historyData?.[3]?.[index],
          Date: formattedDate,
          Excel: historyData?.[0]?.[index],
          users: historyData?.[2]?.[index],
          Upload: historyData?.[4]?.[index],
          Status: (
            <div className="voilet_text">
              {historyData?.[2]?.[index] == historyData?.[4]?.[index]
                ? "Complete"
                : "Pending"}
            </div>
          ),
        };
        frmtData.push(data);
      });
      const sortedList = frmtData.sort((a, b) => b.timestamp - a.timestamp);
      setFormatData(sortedList);
    }
  }, [historyData]);

  async function getconnect() {
    if (
      walletConnection &&
      web3 &&
      address &&
      address != undefined &&
      address != "" &&
      network != ""
    ) {
      try {
        if (web3 && address) {
          try {
            //CONTRACT
            let Exlcontract = new web3.eth.Contract(
              ExcelUploadContractABI,
              config.ExcelUploadContract
            );
            let ownerAddress_val = await Exlcontract.methods.admin().call();
            //Valid - Owner address
            if (ownerAddress_val != address) {
              toast.error(
                "Please connect the wallet to the owner address",
                toasterOption
              );
              setFormatData([]);
              return false;
            } else {
              let history = await Exlcontract.methods
                .getAllUploadHistories()
                .call();
              setHistoryData(history);
              console.log(JSON.parse(localStorage.getItem("transaction_hash")));
              setTransHash(
                JSON.parse(localStorage.getItem("transaction_hash"))
              );
              setFormatData([]);
            }
          } catch (err) {
            console.log(err);
            return false;
          }
        }
        //else{
        //   toast.error("Wallet not connected", toasterOption);
        // }
      } catch (err) {
        console.log(err);
      }
    }
    // else {
    //   toast.error("Wallet not connected.", toasterOption);
    // }
  }
  return (
    <>
      <h2 className="h2tag p-2">Last Updated Transaction Hash</h2>
      <div class="white_box">
        <div className="table-responsive">
          <table class="table table-dark custom_table">
            <thead>
              <tr>
                <th scope="col">Users</th>
                <th scope="col">Transaction Hash</th>
              </tr>
            </thead>
            <tbody>
              {transHash?.map((obj) => {
                return (
                  <>
                    <tr>
                      <td scope="row">{obj?.user}</td>
                      <td>{obj?.hash}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <h2 className="h2tag p-2">Upload History</h2>
      <div className="white_box">
        <div className="table_content">
          <DataTable
            columns={columns}
            data={formatData}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            pagination
          />
        </div>
      </div>
    </>
  );
}
