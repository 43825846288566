var key = {};
var NODE_ENV = "local";

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

if (NODE_ENV === "production") {

  key = {
    partialUpload: 50,
    BUSDcontract: "0x64575A5633C6c4BE143b0f2d67c5a529B1a7A83a",
    ExcelUploadContract: "0x34b96a9129724fc6e5694D4E0454A477628C0104",
    ScanUrl: "https://testnet.bscscan.com/tx/",
    NetworkId: 97,
    decimals: 1e18,
    infuraRpc: "https://bsc-testnet.publicnode.com",
    toasterOption: toasterOption,
  };

} else {
  key = {
    partialUpload: 500,
    BUSDcontract: "0x8A7dcB46d9b598CfC2EeD49aD19ecB39a2305Ee9",
    ExcelUploadContract: "0x359c58A3cB0887509FDEeAaAFE26f3b662A3d4f2",
    //ExcelUploadContract: "0xCd7e6D9C52C3e3775f5cd473B1DB7Acd52438c8a",
    ScanUrl: "https://testnet.bscscan.com/tx/",
    NetworkId: 97,
    decimals: 1e18,
    infuraRpc: "https://bsc-testnet.publicnode.com",
    toasterOption: toasterOption,
  };
}
export default key;

